import React, { createContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Api from "./services/Api";
import MainLoading from "./components/MainLoading";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AppContext } from "./contexts/AppContext";
import GlobalStyle from "./GlobalStyle";
import Account from "./pages/Account";
import Patients from "./pages/Patients";
import Patient from "./pages/Patient";
import Dashboard from "./pages/Dashboard";
import { Toaster } from "react-hot-toast";
import { AccountModel } from "./models/AccountModel";
import { PatientModel } from "./models/PatientModel";
import PatientDetails from "./pages/PatientDetails";
import PatientWorkAndTraining from "./pages/PatientWorkAndTraining";
import PatientAccount from "./pages/PatientAccount";
import BehaviorsModule from "./pages/BehaviorsModule";
import ResetPassword from "./pages/ResetPassword";
import SignupSuccess from "./pages/SignupSuccess";

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLogged, setIsLogged] = useState<boolean>(false);

  const [account, setAccount] = useState<AccountModel>();
  const [patientContext, setPatientContext] = useState<PatientModel>();
  const [behaviorActiveRecords, setBehaviorActiveRecords] = useState<[]>([]);
  const [patients, setPatients] = useState<PatientModel[]>([]);
  const [currentPatient, setCurrentPatient] = useState<PatientModel>();

  const [isNavOpen, setIsNavOpen] = useState(false);

  async function preload() {
    try {
      if (!account) {
        const account = await Api.getAccount();
        if (account) {
          setAccount(account);
        }
      }
      await new Promise((r) => setTimeout(r, 400));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    preload();
  }, []);

  if (isLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <MainLoading></MainLoading>
      </div>
    );
  }

  return (
    <AppContext.Provider
      value={{
        setIsLoading: setIsLoading,
        isLogged: isLogged,
        setIsLogged: setIsLogged,
        account: account,
        setAccount: setAccount,
        currentPatient: currentPatient,
        setCurrentPatient: setCurrentPatient,

        isNavOpen: isNavOpen,
        toggleNav: () => {
          setIsNavOpen(!isNavOpen);
        },

        patientContext: patientContext,
        setPatientContext: setPatientContext,
        // setPatientContext: (p: PatientModel) => {
        //   setPatientContext({ ...p });
        // },
      }}
    >
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerStyle={{
          zIndex: "999999999999999999999999",
          marginTop: "78px",
        }}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#fff",
            color: "#0c0d48",
          },
        }}
      />

      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Patients />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup-success" element={<SignupSuccess />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/account" element={<Account />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/patients/new" element={<PatientDetails />} />
          <Route path="/patients/:id" element={<Patient />} />
          <Route path="/patients/:id/:tab" element={<Patient />} />
          <Route path="/patients/:id/details" element={<PatientDetails />} />
          <Route
            path="/patients/:id/work-and-training"
            element={<PatientWorkAndTraining />}
          />
          <Route path="/accounts/new" element={<PatientAccount />} />
          <Route path="/accounts/:id" element={<PatientAccount />} />
          <Route path="/b-tracker" element={<BehaviorsModule />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
