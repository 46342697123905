import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Checkbox from "../components/form/Checkbox";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import { AppContext } from "../contexts/AppContext";
import Api from "../services/Api";

export default function Signup() {
  const { setAccount } = useContext(AppContext);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [sex, setSex] = useState<string>("");
  const [birthday, setBirthday] = useState<string | null>(null);
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);

  let navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {}, []);

  async function signup() {
    try {
      if (!firstName) return toast.error("Inserisci il tuo nome");
      if (!lastName) return toast.error("Inserisci il tuo cognome");
      if (!email) return toast.error("Inserisci la tua email");
      if (!password) return toast.error("Inserisci una password");
      if (!privacyChecked)
        return toast.error("Seleziona la casella di accettazione");
      setPending(true);
      const account = await Api.signup(
        firstName,
        lastName,
        email,
        password,
        sex,
        birthday!
      );
      if (account) {
        setAccount(account);
        localStorage.setItem("accessToken", account.token);
        navigate("/");
      } else {
        toast.error("Dati errati o mancanti");
      }
      setPending(false);
    } catch (err) {
      console.log(err);
      toast.error("Errore generico");
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#0c0d48",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url("/images/watson-background-overlay.png")`,
          backgroundPosition:"bottom center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{ maxWidth: "400px", paddingTop: "60px", margin: "0 auto" }}
        >
          <img
            src="/logo-watson.png"
            alt="logo"
            width="100%"
            style={{
              maxHeight: "60px",
              width: "auto",
              margin: "0 auto",
              display: "block",
            }}
          />
          <Box className="mt-3 p-4">
            <h3 className="mb-3">Registrati</h3>
            <div className="row gy-3">
              <div className="col-12">
                <Input
                  label="Email"
                  value={email}
                  type="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                ></Input>
              </div>
              <div className="col-12">
                <Input
                  label="Inserisci una password"
                  placeholder="Password"
                  value={password}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></Input>
              </div>
              <div className="col-12">
                <Input
                  label="Nome"
                  value={firstName}
                  type="text"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                ></Input>
              </div>
              <div className="col-12">
                <Input
                  label="Cognome"
                  value={lastName}
                  type="text"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                ></Input>
              </div>
              {/* <div className="col-12">
                <Select
                  label="Sesso"
                  placeholder="Seleziona"
                  options={sexOptions}
                  value={sex}
                  onChange={(e) => {
                    setSex(e.target.value);
                  }}
                ></Select>
              </div>
              <div className="col-12">
                <Input
                  label="Data di nascita"
                  value={birthday}
                  type="date"
                  onChange={(e) => {
                    setBirthday(e.target.value);
                  }}
                ></Input>
              </div> */}
              <div className="col-12">
                <Checkbox
                  checked={privacyChecked}
                  onChange={() => {
                    setPrivacyChecked(!privacyChecked);
                  }}
                >
                  Ho letto e accetto l'informativa sulla{" "}
                  <a
                    href="https://watsongest.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>
                </Checkbox>
              </div>

              <div className="col-12">
                <Button onClick={signup} fullWidth loading={pending}>
                  Registrati
                </Button>
              </div>

              <div className="col-12 text-center">
                <NavLink
                  to="/login"
                  style={{
                    textDecoration: "none",
                    color: "#7b8db5",
                  }}
                >
                  Hai già un account? Accedi qui.
                </NavLink>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}
