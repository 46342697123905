import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Box from "../components/Box";
import Button from "../components/form/Button";
import InputLabel from "../components/form/InputLabel";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";
import { calculateAge, renderSexIcon } from "../services/utils";

export default function Patients() {
  const navigate = useNavigate();
  const { setPatientContext } = useContext(AppContext);
  const [patients, setPatients] = useState<PatientModel[]>([]);
  const [pending, setPending] = useState(true);

  async function fetchPatients() {
    setPending(true);
    const patients = await Api.getPatients();
    if (patients) {
      setPatients(patients);
    }
    setPending(false);
  }

  useEffect(() => {
    fetchPatients();
  }, []);

  if (pending)
    return (
      <MainLayout title="Pazienti">
        <div className="text-center pt-4">
          <span
            className="spinner-border spinner-border text-secondary"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </MainLayout>
    );

  return (
    <>
      <MainLayout title="Pazienti">
        {/* <div className="mb-3">
          <Button
            onClick={() => {
              navigate("/patients/new");
            }}
          >
            Nuovo paziente
          </Button>
        </div> */}
        <Box>
          {!patients.length ? (
            <div className="text-center py-3">
              <img
                src="/images/no-data.svg"
                alt=""
                style={{
                  maxWidth: "200px",
                  width: "100%",
                }}
              />
              <br />
              <h5 className="mt-3">Nessun paziente inserito</h5>
              <p>Creare un nuovo paziente</p>
              <Button
                onClick={() => {
                  navigate("/patients/new");
                }}
                iconClass="fa-solid fa-circle-plus"
              >
                Nuovo Paziente
              </Button>
            </div>
          ) : (
            <>
              <div className="d-flex mb-3">
                <h3 className="flex-grow-1">Pazienti</h3>
                <Button
                  onClick={() => {
                    navigate("/patients/new");
                  }}
                  iconClass="fa-solid fa-circle-plus"
                >
                  Paziente
                </Button>
              </div>
              <table className="basic-table text-center">
                <thead>
                  <tr>
                    <th className="text-center">
                      <InputLabel>Nome</InputLabel>
                    </th>
                    <th className="text-center">
                      <InputLabel>Età</InputLabel>
                    </th>
                    <th className="text-center">
                      <InputLabel>Sesso</InputLabel>
                    </th>
                    <th className="text-center">
                      <InputLabel>Creato</InputLabel>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patients.map((patient: PatientModel) => (
                    <tr
                      key={patient._id}
                      onClick={() => {
                        setPatientContext(patient);
                        navigate(`/patients/${patient._id}`);
                      }}
                    >
                      <td>
                        {patient.firstName} {patient.lastName}
                      </td>
                      <td> {calculateAge(patient.birthday)}</td>
                      <td>{renderSexIcon(patient.sex)}</td>
                      <td>{moment(patient.createdAt).format("DD/MM/yyyy")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Box>
      </MainLayout>
    </>
  );
}
