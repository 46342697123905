import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import { AppContext } from "../contexts/AppContext";
import Api from "../services/Api";

export default function Login() {
  const { setAccount } = useContext(AppContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  let navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {}, []);

  async function login(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!email || !password) return toast.error("Credenziali errate");
    setPending(true);
    const account = await Api.login(email, password);
    if (account) {
      setAccount(account);
      localStorage.setItem("accessToken", account.token);
      navigate("/");
    } else {
      toast.error("Credenziali errate");
      setPassword("");
    }
    setPending(false);
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "#0c0d48",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url("/images/watson-background-overlay.png")`,
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{ maxWidth: "400px", paddingTop: "60px", margin: "0 auto" }}
        >
          <img
            src="/logo-watson.png"
            alt="logo"
            width="100%"
            style={{
              maxHeight: "60px",
              width: "auto",
              margin: "0 auto",
              display: "block",
            }}
          />
          <Box className="mt-3 p-4">
            <h3 className="mb-3">Accedi al tuo account</h3>
            <form autoComplete="on" onSubmit={login}>
              <div className="row gy-3">
                <div className="col-12">
                  <Input
                    label="Email"
                    value={email}
                    type="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                    }}
                    autoComplete="username email"
                    required
                  ></Input>
                </div>
                <div className="col-12">
                  <Input
                    label="Password"
                    value={password}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    autoComplete="current-password"
                    required
                  ></Input>
                </div>
                <NavLink
                  to="/reset-password"
                  style={{
                    textDecoration: "none",
                    color: "#7b8db5",
                    marginTop: "4px",
                    display: "inline-block",
                    fontSize: "14px",
                  }}
                >
                  Password dimenticata?
                </NavLink>
                <div className="col-12">
                  <Button submit fullWidth loading={pending}>
                    Accedi
                  </Button>
                </div>
                <div className="col-12  text-center">
                  <NavLink
                    to="/signup"
                    style={{
                      textDecoration: "none",
                      color: "#7b8db5",
                    }}
                  >
                    Non hai un account? Registrati qui.
                  </NavLink>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </div>
    </>
  );
}
