import { createContext } from "react";
import { AccountModel } from "../models/AccountModel";
import { PatientModel } from "../models/PatientModel";

export interface AppContextInterface {
  setIsLoading: Function;
  isLogged: boolean;
  setIsLogged: Function;
  account?: AccountModel;
  setAccount: Function;
  currentPatient?: PatientModel;
  setCurrentPatient: Function;

  isNavOpen: boolean;
  toggleNav: Function;

  patientContext?: PatientModel;
  setPatientContext: Function;
}

export const AppContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);
