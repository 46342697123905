import styled from "styled-components";

const PatientTabs = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  overflow-y: auto;

  li {
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-right: 5px;
    padding: 0px 14px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: #0c0d480a;
    }

    &.active {
      background-color: #14c3bc21;
      color: #00afa8;
    }
  }
`;

export default PatientTabs;
