import styled from "styled-components";

const Box = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

export default Box;
