import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import MainLoading from "../components/MainLoading";
import Navigation from "../components/Navigation";

interface MainLayoutProps {
  isLoading?: boolean;
  children: any;
  title: string;
}

const Page = styled.div`
  padding-left: 280px; // Navigation width

  @media screen and (max-width: 992px) {
    padding-left: initial;
  }
`;

const MainLayout = ({ isLoading, children, title }: MainLayoutProps) => {
  return (
    <>
      <Navigation />
      <Page>
        <Header title={title} />
        <div className="p-3">
          {isLoading ? <MainLoading></MainLoading> : children}
        </div>
      </Page>
    </>
  );
};

export default MainLayout;
