import React, { useContext, useEffect, useState } from "react";
import { NavLink, redirect, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BehaviorCard from "../components/BehaviorCard";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { AccountModel } from "../models/AccountModel";
import { BehaviorModel } from "../models/BehaviorModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";

export default function Account() {
  const navigate = useNavigate();
  const { account } = useContext(AppContext);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (account) {
      setFirstName(account.firstName);
      setLastName(account.lastName);
      setEmail(account.email);
    }
  }, [account]);

  return (
    <>
      <MainLayout title="Account">
        <Box>
          <div className="row gy-3">
            <div className="col-12">
              <Input
                label="Nome"
                type="text"
                value={firstName}
                placeholder=""
                onChange={(e) => {
                  setFirstName(e.currentTarget.value);
                }}
                disable
              ></Input>
            </div>
            <div className="col-12">
              <Input
                label="Cognome"
                type="text"
                value={lastName}
                placeholder=""
                onChange={(e) => {
                  setLastName(e.currentTarget.value);
                }}
                disable
              ></Input>
            </div>
            {/* <div className="col-12">
              <Button fullWidth onClick={() => {}}>
                Salva
              </Button>
            </div> */}
          </div>
        </Box>

        <Box>
          <div className="row gy-3">
            <div className="col-12">
              <Input
                label="Email"
                type="text"
                value={email}
                placeholder=""
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                disable
              ></Input>
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
