import axios from "axios";

export default axios.create({
  //baseURL: `http://localhost:4000/dev/admin/`,
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
