import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import PatientTabs from "../components/form/PatientTabs";
import Select from "../components/form/Select";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { AccountModel } from "../models/AccountModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";

export default function PatientWorkAndTraining() {
  const { patientContext, setPatientContext } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [pending, setPending] = useState<boolean>(false);

  const [degree, setDegree] = useState<string>("");
  const [school, setSchool] = useState<string>("");
  const [jobCategory, setJobCategory] = useState<string>("");
  const [job, setJob] = useState<string>("");

  useEffect(() => {
    if(!patientContext) return;
    setDegree(patientContext.degree);
    setSchool(patientContext.school);
    setJobCategory(patientContext.jobCategory);
    setJob(patientContext.job);
  }, [patientContext]);

  async function updatePatient() {
    setPending(true);
    const patientUpdated = await Api.updatePatient({
      _id: patientContext?._id,
      degree: degree,
      school: school,
      jobCategory: jobCategory,
      job: job,
    });
    if (patientUpdated) {
      toast.success("Aggiornato!");
      setPatientContext(patientUpdated);
      navigate(-1);
    }
    setPending(false);
  }

  return (
    <>
      <MainLayout title={`Lavoro e formazione`}>
        <Box>
          <div className="row gy-3">
            <div className="col-12">
              <Input
                label="Titolo di studio"
                type="text"
                value={degree}
                onChange={(e) => setDegree(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <Input
                label="Scuola"
                type="text"
                value={school}
                onChange={(e) => setSchool(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <Input
                label="Categoria professione"
                type="text"
                value={jobCategory}
                onChange={(e) => setJobCategory(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <Input
                label="Professione"
                type="text"
                value={job}
                onChange={(e) => setJob(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <Button onClick={updatePatient} fullWidth loading={pending}>
                Salva
              </Button>
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
