import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import { AppContext } from "../contexts/AppContext";
import { AccountModel } from "../models/AccountModel";
import Api from "../services/Api";

export default function SignupSuccess() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const signupToken = searchParams.get("signupToken");
  const [pending, setPending] = useState<boolean>(true);
  const [isVerified, setIsVerified] = useState<boolean | undefined>();

  async function autoConfirmSignup() {
    setPending(true);
    if (signupToken) {
      const isVerified = await Api.confirmSignup(signupToken);
      await new Promise((resolve) => setTimeout(resolve, 300));
      setIsVerified(isVerified);
    } else {
      setIsVerified(false);
    }
    setPending(false);
  }

  useEffect(() => {
    autoConfirmSignup();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#0c0d48",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url("/images/watson-background-overlay.png")`,
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{ maxWidth: "400px", paddingTop: "60px", margin: "0 auto" }}
        >
          <img
            src="/logo-watson.png"
            alt="logo"
            width="100%"
            style={{
              maxHeight: "60px",
              width: "auto",
              margin: "0 auto",
              display: "block",
            }}
          />

          <Box className="mt-3 p-5 text-center">
            {pending ? (
              <>
                <span
                  className="spinner-border spinner-border text-secondary"
                  role="status"
                  aria-hidden="true"
                ></span>
                <h4 className="mt-4">Verifica in corso...</h4>
              </>
            ) : isVerified ? (
              <>
                <h4>Account verificato!</h4>
                <p>Puoi chiudere questa pagina.</p>
              </>
            ) : (
              <>
                <h4>Errore!</h4>
                <p>
                  Non è stato possibile verificare l'account. Ricaricare la
                  pagina o contattare l'assistenza.
                </p>
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
}
