import React, { useEffect, useState } from "react";
import { NavLink, redirect, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BehaviorCard from "../components/BehaviorCard";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import MainLayout from "../layouts/MainLayout";
import { AccountModel } from "../models/AccountModel";
import { BehaviorModel } from "../models/BehaviorModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";

export default function BehaviorsModule() {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <>
      <MainLayout title="Monitoraggio comportamento">
        <Box>
          <div className="row gy-3">
            <div className="col-12">
              <img
                src="/images/monitoraggio-comportamento.jpg"
                alt=""
                style={{
                  maxWidth: "300px",
                  width: "100%",
                }}
              />
              <h3>B-Tracker</h3>
              <p>
                B-Tracker è uno strumento di monitoraggio che permette di
                registrare comportamenti e visualizzarli in modo chiaro e
                intuitivo attraverso grafici.
              </p>
              <h3>Come utilizzarlo?</h3>
              <p>
                Per utilizzare B-Tracker, è necessario seguire i seguenti
                passaggi:
                <ul>
                  <li>Crea un paziente dalla scheda "Pazienti"</li>
                  <li>
                    All'interno della scheda del paziente, cliccare sulla voce
                    "Moduli"
                  </li>
                  <li>
                    Cliccare "Apri B-Tracker". Verrai reindirizzato al modulo
                    esterno
                  </li>
                  <li>
                    Creare, modifica o elimina i comportamenti da monitorare
                  </li>
                  <li>
                    Clicca "registra" qualora tu voglia rilevare un
                    comportamento
                  </li>
                  <li>
                    Nella scheda "Storico" puoi accedere a tutte le rilevazioni
                    effettute da te o dal paziente
                  </li>
                  <li>
                    Dalla scheda "Analisi" puoi visualizzare i grafici relativi
                    alle rilevazioni
                  </li>
                </ul>
              </p>{" "}
              <p>
                Per permettere al tuo paziente di utilizzare B-Tracker in
                autonomia, è necessario seguire i seguenti passaggi:
                <ul>
                  <li>
                    Dalla scheda del paziente, clicca la voce "Account
                    collegati"
                  </li>
                  <li>
                    Clicca "Nuovo account", e inserisci l'email del paziente.
                  </li>
                  <li>
                    Il paziente potrà rilevare i suoi comportamenti collegandosi
                    al suo B-Tracker in autonomia, mentre tu potrai accedere
                    alle rilevazioni registrate seguendo gli step
                    precedentemente indicati.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
