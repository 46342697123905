import moment from "moment";

export function calculateAge(dateOfBirth: Date | string | undefined): string {
  if (!dateOfBirth) return "";
  const now = moment();
  const birthDate = moment(dateOfBirth);
  const years = now.diff(birthDate, "years");
  return years.toString();
}

export function renderSexIcon(sex: string, showColor: boolean = true) {
  switch (sex) {
    case "male":
      return (
        <i
          className="fa-solid fa-mars"
          style={showColor ? { color: "#006aff" } : {}}
        ></i>
      );
    case "female":
      return (
        <i
          className="fa-solid fa-venus"
          style={showColor ? { color: "#fc00ff" } : {}}
        ></i>
      );
    default:
      return <i className="fa-solid fa-genderless"></i>;
  }
}

export function renderSexLabel(sex: string) {
  switch (sex) {
    case "male":
      return "Maschio";
    case "female":
      return "Femmina";
    default:
      return "Altro";
  }
}

export function renderTherapyStatusLabel(therapyStatus: string) {
  switch (therapyStatus) {
    case "inTreatment":
      return "In terapia";
    case "completed":
      return "Completata";
    case "discontinued":
      return "Dropout";
    default:
      return "Nessun stato terapia";
  }
}
