import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import { AppContext } from "../contexts/AppContext";
import { AccountModel } from "../models/AccountModel";
import Api from "../services/Api";

export default function ResetPassword() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetPasswordToken = searchParams.get("resetPasswordToken");
  const [email, setEmail] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [pending, setPending] = useState<boolean>();
  const [instrunctionsSent, setInstrunctionsSent] = useState<
    boolean | undefined
  >();
  const [resetSuccess, setResetSuccess] = useState<boolean | undefined>();

  async function forgotPassword() {
    try {
      setPending(true);
      if (email) {
        const instrunctionsSent = await Api.forgotPassword(email);
        setInstrunctionsSent(instrunctionsSent);
      }
      setPending(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function resetPassword(event: React.FormEvent<HTMLFormElement>) {
    try {
      event.preventDefault();
      setPending(true);
      if (resetPasswordToken) {
        const resetSuccess = await Api.resetPassword(
          resetPasswordToken,
          newPassword
        );
        await new Promise((resolve) => setTimeout(resolve, 300));
        setResetSuccess(resetSuccess);
      } else {
        setResetSuccess(false);
      }
      setPending(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#180c48", //"#0c0d48",
          minHeight: "100vh",
          width: "100vw",
          backgroundImage: `url("/images/watson-background-overlay.png")`,
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{ maxWidth: "400px", paddingTop: "60px", margin: "0 auto" }}
        >
          <img
            src="/logo-watson.png"
            alt="logo"
            width="100%"
            style={{
              maxHeight: "60px",
              width: "auto",
              margin: "0 auto",
              display: "block",
            }}
          />

          {!resetPasswordToken ? (
            !instrunctionsSent ? (
              <>
                <Box className="mt-3 p-4">
                  <h3 className="mb-3">Reimposta la password</h3>
                  <p>
                    Inserisci il tuo indirizzo email, ti invieremo le istruzioni
                    per reimpostare la nuova password.
                  </p>
                  <div className="row gy-3">
                    <div className="col-12">
                      <Input
                        label="Email"
                        value={email}
                        type="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setEmail(e.target.value);
                        }}
                        autoComplete="username email"
                        required
                      ></Input>
                    </div>
                    <div className="col-12">
                      <Button
                        fullWidth
                        loading={pending}
                        onClick={forgotPassword}
                      >
                        Cambia password
                      </Button>
                    </div>
                  </div>
                </Box>
              </>
            ) : (
              <Box className="mt-3 p-5 text-center">
                <h4>Controlla la posta</h4>
                <p>
                  Ti abbiamo inviato le istruzioni per reimpostare la nuova
                  password.
                </p>
              </Box>
            )
          ) : typeof resetSuccess === "undefined" ? (
            <Box className="mt-3 p-4">
              <h3 className="mb-3">Reimposta la password</h3>
              <form onSubmit={resetPassword}>
                <div className="row gy-3">
                  <div className="col-12">
                    <Input
                      label="Nuova Password"
                      value={newPassword}
                      type="password"
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      required
                    ></Input>
                  </div>
                  <div className="col-12">
                    <Button submit fullWidth loading={pending}>
                      Cambia password
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          ) : (
            <Box className="mt-3 p-5 text-center">
              {resetSuccess ? (
                <>
                  <h4>Password aggiornata!</h4>
                  <p>Puoi chiudere questa pagina o tornare alla login.</p>
                </>
              ) : (
                <>
                  <h4>Errore!</h4>
                  <p>
                    Non è stato possibile aggiornare la password. Ricaricare la
                    pagina o contattare l'assistenza.
                  </p>
                </>
              )}
              <div className="mt-3 text-center">
                <NavLink
                  to="/login"
                  style={{
                    textDecoration: "none",
                    color: "#7b8db5",
                  }}
                >
                  Per andare alla login, clicca qui.
                </NavLink>
              </div>
            </Box>
          )}
        </div>
      </div>
    </>
  );
}
