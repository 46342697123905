import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Checkbox from "../components/form/Checkbox";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import PatientTabs from "../components/form/PatientTabs";
import Select from "../components/form/Select";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { AccountModel } from "../models/AccountModel";
import { PatientAccountModel } from "../models/PatientAccountModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";

export default function PatientAccount() {
  const { patientContext } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const patientData = state?.patient;
  const accountData = state?.account;
  const isNew = accountData ? false : true;
  //const [patient, setPatient] = useState<PatientModel>();
  const [pending, setPending] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [accountSearched, setAccountSearched] = useState<
    PatientAccountModel | undefined
  >();

  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [attachCheck, setAttachCheck] = useState<boolean>(false);

  useEffect(() => {
    if (!isNew) {
      //setPatient(accountData);
      setEmail(accountData.email);
      setFirstName(accountData.firstName);
      setCategory(accountData.category);
    }
  }, [accountData]);

  function checkErrors() {
    if (!email)
      return toast.error("Inserisci l'email dell'account da associare");
    if (!firstName) return toast.error("Inserisci il nome del paziente");
    if (!category) return toast.error("Seleziona il tipo di account");
    if (!attachCheck)
      return toast.error(
        "Per procedere, devi selezionare la casella di accettazione"
      );
  }

  async function createPatientAccount() {
    if (checkErrors()) return;
    setPending(true);
    const isCreated = await Api.createPatientAccount(patientData?._id, {
      email: email,
      firstName: firstName,
      category: category,
    } as PatientAccountModel);
    if (isCreated === true) {
      toast.success("Creato!");
      navigate(-1);
    }
    setPending(false);
  }

  async function searchAccounts() {
    setPending(true);
    setSearched(false);
    const accountsSearched = await Api.searchAccounts(email);
    if (accountsSearched && accountsSearched.length) {
      setAccountSearched(accountsSearched[0]);
    } else {
      setAccountSearched(undefined);
    }
    setSearched(true);
    setPending(false);
  }

  useEffect(() => {
    if (searched === true) {
      setSearched(false);
    }
  }, [email]);

  async function attachAccount() {
    setPending(true);
    const attached = await Api.attachAccount(
      patientData?._id!,
      accountSearched?._id!
    );
    if (attached) {
      toast.success("Account collegato");
      navigate(-1);
    }
    setPending(false);
  }

  const categoryOptions = [
    {
      value: "student",
      label: "Studente",
    },
    {
      value: "parent",
      label: "Studente",
    },
    {
      value: "parent",
      label: "Genitore",
    },
    {
      value: "teacher",
      label: "Insegnante",
    },
    {
      value: "educator",
      label: "Educatore",
    },
    {
      value: "behaviorTechnician",
      label: "Tecnico del comportamento",
    },
    {
      value: "other",
      label: "Altro",
    },
  ];

  return (
    <>
      <MainLayout title={isNew ? "Collega account" : `Dati account`}>
        <Box>
          <div className="row gy-3">
            <div className="col-12">
              <Input
                label="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            {!searched && (
              <div className="col-12">
                <Button
                  onClick={() => {
                    searchAccounts();
                  }}
                  fullWidth
                  loading={pending}
                  variant="light"
                >
                  Avanti
                </Button>
              </div>
            )}
            {searched ? (
              !accountSearched ? (
                <>
                  <div className="col-12">
                    <Input
                      label="Nome"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.currentTarget.value)}
                    />
                  </div>
                  <div className="col-12">
                    <Select
                      label="Categoria"
                      placeholder="Seleziona"
                      value={category}
                      options={categoryOptions}
                      onChange={(e) => setCategory(e.currentTarget.value)}
                    />
                  </div>
                  <p>
                    <Checkbox
                      checked={attachCheck}
                      onChange={() => {
                        setAttachCheck(!attachCheck);
                      }}
                    >
                      Ho compreso che cliccando "Collega", l'account{" "}
                      <b>{email}</b> potrà accedere ai dati relativi al paziente{" "}
                      <b>
                        {patientContext?.firstName} {patientContext?.lastName}
                      </b>
                      <br />
                      Verrà inviata una email automatica con le informazioni
                      d'accesso all'indirizzo indicato.
                    </Checkbox>
                  </p>
                  <div className="col-12 mt-0">
                    <Button
                      onClick={createPatientAccount}
                      fullWidth
                      loading={pending}
                    >
                      Crea
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    <Checkbox
                      checked={attachCheck}
                      onChange={() => {
                        setAttachCheck(!attachCheck);
                      }}
                    >
                      Ho compreso che cliccando "Collega", l'account{" "}
                      <b>{accountSearched.email}</b> potrà accedere ai dati
                      relativi al paziente{" "}
                      <b>
                        {patientContext?.firstName} {patientContext?.lastName}
                      </b>
                      .
                      <br />
                      Verrà inviata una email automatica con le informazioni
                      d'accesso all'indirizzo indicato.
                    </Checkbox>
                  </p>
                  <div className="col-12 mt-0">
                    <Button
                      onClick={() => {
                        attachAccount();
                      }}
                      fullWidth
                      disabled={!attachCheck}
                      loading={pending}
                    >
                      Collega
                    </Button>
                  </div>
                </>
              )
            ) : (
              <></>
            )}
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
