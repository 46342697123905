import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../contexts/AppContext";
import Button from "./form/Button";

interface HeaderProps {
  title: string;
}

const HeaderStyled = styled.div`
  background-color: #fff;
  padding: 16px 0px;
  position: sticky;
  top: 0px;
  z-index: 99999;
  box-shadow: 0px 0px 20px #0000000d;

  #hamburger-menu {
    display: none;
  }

  @media screen and (max-width: 992px) {
    #hamburger-menu {
      display: block;
    }

    #header-button-account {
      display: none;
    }
  }
`;

const BackArrowButton = styled.button`
  background-color: #15c3bc;
  border: none;
  border-radius: 100px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  i {
    font-size: 30px;
    color: #fff;
  }
`;

const HeaderButton = styled.button`
  height: 45px;
  width: 45px;
  border: none;
  background-color: transparent;
  border-radius: 6px;
  font-size: 24px;
  color: #0c0d48;

  &:hover,
  &:focus {
    background-color: #f9fbfc;

    i {
      color: #14c3bc;
    }
  }

  a {
    color: inherit;
  }
`;

const HeaderDropdown = styled.div`
  background-color: #fff;
  position: fixed;
  top: 70px;
  right: 16px;
  box-shadow: 0px 0px 50px #00000040;
  border-radius: 6px;
  user-select: none;
  min-width: 220px;

  a {
    padding: 16px 10px;
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    button {
      background-color: transparent;
      border: none;
      width: 100%;
      text-align: left;
      padding: 16px 16px;
      color: #0c0d48;
      border-bottom: 1px solid #f1f3f9;
      font-size: 16px;
      font-weight: 600;

      i {
        margin-right: 10px;
      }
    }

    li:last-child {
      button {
        border: none;
      }
    }
  }
`;

export default function Header({ title }: HeaderProps) {
  const { toggleNav } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState<boolean>(false);
  const notificationButtonRef = useRef<HTMLButtonElement>(null);
  const [showAccountDropdown, setShowAccountDropdown] =
    useState<boolean>(false);
  const accountButtonRef = useRef<HTMLButtonElement>(null);

  function toggleNotificationBox() {
    setShowNotificationDropdown(!showNotificationDropdown);
  }
  function toggleAccountBox() {
    setShowAccountDropdown(!showAccountDropdown);
  }
  function hideDropdowns(event: any) {
    if (
      notificationButtonRef.current &&
      !notificationButtonRef.current.contains(event.target)
    ) {
      setShowNotificationDropdown(false);
    }
    if (
      accountButtonRef.current &&
      !accountButtonRef.current.contains(event.target)
    ) {
      setShowAccountDropdown(false);
    }
  }

  function logout() {
    if (window.confirm("Vuoi disconnettere l'account?")) {
      localStorage.removeItem("accessToken");
      navigate("/login");
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", hideDropdowns);
  }, []);

  return (
    <HeaderStyled className="px-3">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {location.pathname.split("/").length !== 2 ? (
            <BackArrowButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="icofont-arrow-left"></i>
            </BackArrowButton>
          ) : (
            <></>
          )}
          <h3 className="m-0">{title}</h3>
        </div>
        <div className="d-flex gap-2 justify-content-between align-items-center">
          {/* <HeaderButton>
            <i className="icofont-notification"></i>
          </HeaderButton> */}
          <HeaderButton
            onClick={toggleNotificationBox}
            ref={notificationButtonRef}
          >
            <i className="fa-solid fa-bell"></i>
          </HeaderButton>
          <HeaderButton
            id="header-button-account"
            onClick={toggleAccountBox}
            ref={accountButtonRef}
          >
            <i className="fa-solid fa-circle-user"></i>
          </HeaderButton>
          <HeaderButton
            id="hamburger-menu"
            onClick={() => {
              toggleNav();
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </HeaderButton>
        </div>
        {showNotificationDropdown && (
          <HeaderDropdown>
            <div className="p-3 text-center">Nessuna notifica</div>
          </HeaderDropdown>
        )}
        {showAccountDropdown && (
          <HeaderDropdown>
            <ul>
              <li>
                <button
                  onClick={() => {
                    navigate("/account");
                  }}
                >
                  <i className="fa-solid fa-user"></i>
                  Account
                </button>
              </li>
              <li>
                <button onClick={logout}>
                  <i className="icofont-logout"></i>
                  Esci
                </button>
              </li>
            </ul>
          </HeaderDropdown>
        )}
      </div>
    </HeaderStyled>
  );
}
