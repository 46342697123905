import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import PatientTabs from "../components/form/PatientTabs";
import Select from "../components/form/Select";
import SimpleIconButton from "../components/SimpleIconButton";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { AccountModel } from "../models/AccountModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";
import { sexOptions } from "../services/GlobalVariables";

export default function PatientDetails() {
  const { patientContext, setPatientContext } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const isNew = !id ? true : false;
  const [pending, setPending] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [sex, setSex] = useState<string>("");
  const [birthday, setBirthday] = useState<Date | null>(null);

  useEffect(() => {
    if (!isNew && patientContext) {
      setFirstName(patientContext.firstName);
      setLastName(patientContext.lastName);
      setSex(patientContext.sex);
      setBirthday(patientContext.birthday);
    }
  }, [patientContext]);

  function checkErrors() {
    if (!firstName) return toast.error("Inserisci il nome del paziente");
    if (!sex) return toast.error("Seleziona il sesso");
    if (!birthday) return toast.error("Seleziona la data di nascita");
  }

  async function createPatient() {
    if (checkErrors()) return;
    setPending(true);
    const isCreated = await Api.createPatient({
      firstName: firstName,
      lastName: lastName,
      sex: sex,
      birthday: birthday,
    } as PatientModel);
    if (isCreated === true) {
      toast.success("Creato!");
      navigate(-1);
    }
    setPending(false);
  }

  async function updatePatient() {
    if (checkErrors()) return;
    setPending(true);
    const patientUpdated = await Api.updatePatient({
      _id: patientContext?._id,
      firstName: firstName,
      lastName: lastName,
      sex: sex,
      birthday: birthday!,
    });
    if (patientUpdated) {
      toast.success("Aggiornato!");
      setPatientContext(patientUpdated);
      navigate(-1);
    }
    setPending(false);
  }

  async function deletePatient() {
    setPending(true);
    if (window.confirm("Vuoi eliminare questo paziente?")) {
      const isDeleted = await Api.deletePatient(patientContext!._id!);
      if (isDeleted === true) {
        // if (patientContext?._id === patientContext._id) {
        //   setCurrentPatient(undefined);
        // }
        //fetchPatients();
        toast.success("Rimosso!");
        navigate(-2);
      } else {
        toast.error("Errore");
      }
    }
    setPending(false);
  }

  return (
    <>
      <MainLayout title={isNew ? "Nuovo paziente" : `Dati paziente`}>
        <Box>
          {!isNew ? (
            <div className="d-flex justify-content-between">
              <h4>{patientContext!.firstName}</h4>

              <SimpleIconButton
                onClick={() => {
                  if (!pending) {
                    deletePatient();
                  }
                }}
              >
                <i
                  className="fa-solid fa-trash"
                  style={{ color: "#dc0000" }}
                ></i>
              </SimpleIconButton>
            </div>
          ) : (
            <></>
          )}

          <div className="row gy-3">
            <div className="col-12">
              <Input
                label="Nome"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <Input
                label="Cognome"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <Select
                label="Sesso"
                placeholder="Seleziona"
                value={sex}
                options={sexOptions}
                onChange={(e) => setSex(e.currentTarget.value)}
              />
            </div>
            <div className="col-12">
              <Input
                label="Data di nascita"
                type="date"
                value={moment(birthday).format("yyyy-MM-DD")}
                onChange={(e) =>
                  setBirthday(moment(e.currentTarget.value).toDate())
                }
              />
            </div>
            <div className="col-12">
              {isNew ? (
                <Button onClick={createPatient} fullWidth loading={pending}>
                  Crea
                </Button>
              ) : (
                <Button onClick={updatePatient} fullWidth loading={pending}>
                  Salva
                </Button>
              )}
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
