import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  BrowserRouter,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import PatientTabs from "../components/form/PatientTabs";
import Select from "../components/form/Select";
import SimpleIconButton from "../components/SimpleIconButton";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { AccountModel } from "../models/AccountModel";
import { PatientAccountModel } from "../models/PatientAccountModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";
import {
  calculateAge,
  renderSexIcon,
  renderSexLabel,
  renderTherapyStatusLabel,
} from "../services/utils";

const HomeworkCard = styled.div`
  background-color: #14c3bc;
  border-radius: 6px;
  color: #ffffff;
  text-decoration: none;
  padding: 20px;
  max-width: 400px;
  background-color: #13146f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%232023C3'/%3E%3Cstop offset='1' stop-color='%2313146F'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%230C0C45' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%230C0C45' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;

  &:hover {
    padding: 20px;
  }

  i {
    font-size: 30px;
  }

  img {
    max-height: 40px;
    margin-bottom: 16px;
  }

  button {
    background-color: #ffffff26;
  }
`;

const UserDetailsCard = styled.div`
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  margin-bottom: 26px;

  .right {
    background-color: #0c0d48;
    color: #fff;
    padding: 16px 20px;
    min-height: 110px;
    display: flex;
    align-items: center;
    position: relative;
    position: relative;
  }

  .between {
    width: 0;
    height: 0;
    border-bottom: 110px solid #0c0d48;
    border-right: 30px solid transparent;
    background-color: #fff;
  }

  .left {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 16px 20px;
    justify-content: center;

    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      row-gap: 6px;
      max-width: 520px;

      i {
        margin-right: 10px;
        color: #15c3bc;
        width: 20px;
        text-align: center;
      }
    }
  }
`;

const AccountList = styled.div`
  list-style: none;

  li {
    border-radius: 6px;
    padding: 10px;
    background-color: #f1f3f9;
    margin-bottom: 10px;
    position: relative;

    .right-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const TableData = styled.table`
  width: 100%;

  tr {
    td {
      padding: 4px 0px;
    }

    td:first-child {
      font-weight: 600;
      padding-right: 16px;
    }
  }
`;

export default function Patient() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const { account, patientContext, setPatientContext } = useContext(AppContext);
  const { state, pathname } = useLocation();
  const [pending, setPending] = useState(true);
  const { id } = useParams();
  const patientId: string = id!;
  const tabs = [
    {
      id: "registry",
      label: "Anagrafica",
    },
    {
      id: "modules",
      label: "Moduli",
    },
    {
      id: "accounts",
      label: "Account collegati",
    },
    {
      id: "medical-history",
      label: "Anamnesi",
      disabled: true,
    },
    {
      id: "appointments",
      label: "Appuntamenti",
      disabled: true,
    },
  ];

  const [activeTab, setActiveTab] = useState<string>(tab || tabs[0].id);
  const [accounts, setAccounts] = useState<PatientAccountModel[]>();

  async function fetchAccounts() {
    await Api.setCurrentPatientId(patientId);
    const accounts = await Api.getAccounts(patientId);
    if (accounts) {
      setAccounts(accounts);
    }
  }

  async function fetchAndSetPatient() {
    setPending(true);
    const patient = await Api.getPatient(patientId);
    if (patient) {
      setPatientContext(patient);
    }
    setPending(false);
  }

  useEffect(() => {
    if (!patientContext) {
      fetchAndSetPatient();
    } else {
      setPending(false);
    }
    fetchAccounts();
  }, [patientContext]);

  async function goToBeTracker() {
    const beTrakerUrl = await Api.getBeTrackerUrl(patientContext?._id!);
    if (beTrakerUrl) {
      window.open(beTrakerUrl, "_blank", "noreferrer");
    }
  }

  async function detachAccount(account_id: string) {
    if (
      window.confirm(
        "Sei sicuro di voler scollegare l'account da questo paziente?"
      )
    ) {
      const detached = await Api.detachAccount(
        patientContext?._id!,
        account_id
      );
      if (detached) {
        toast.success("Account scollgato");
        fetchAccounts();
      }
    }
  }

  function showLockFunctionalityToast() {
    toast.error(
      "Questa funzionalità non è disponibile in questa versione di Watson",
      {
        duration: 3000,
      }
    );
  }

  if (pending)
    return (
      <MainLayout title="Paziente">
        <div className="text-center pt-4">
          <span
            className="spinner-border spinner-border text-secondary"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </MainLayout>
    );

  return (
    <>
      <MainLayout title={`Paziente ${patientContext?.firstName}`}>
        <UserDetailsCard>
          <div className="right">
            <i
              className="fa-solid fa-circle-user"
              style={{ fontSize: "40px", marginRight: "20px" }}
            ></i>
            <div className="">
              <h4 className="mb-0">
                {patientContext?.firstName}{" "}
                {patientContext?.lastName &&
                  patientContext?.lastName.substring(0, 1) + "."}
                <span style={{ fontWeight: 400 }} className="ms-1">
                  {calculateAge(patientContext?.birthday)}
                </span>
              </h4>
              {/* <span>In terapia da 1 mese</span> */}
            </div>
          </div>
          <div className="between"></div>
          <div className="left">
            <div>
              <div>
                {renderSexIcon(patientContext?.sex!, false)}
                {renderSexLabel(patientContext?.sex!)}
              </div>
              <div>
                <i className="fa-solid fa-file-waveform"></i>
                {renderTherapyStatusLabel(patientContext?.therapyStatus!)}
              </div>
              <div>
                {patientContext?.job ? (
                  <>
                    <i className="fa-solid fa-briefcase"></i>
                    {patientContext?.job}
                  </>
                ) : (
                  <>
                    <i className="fa-solid fa-graduation-cap"></i>
                    {patientContext?.degree || "-"}
                  </>
                )}
              </div>
              {/* <div>
                <i className="fa-solid fa-heart"></i>Single
              </div>
              <div>
                <i className="fa-solid fa-heart-circle-check"></i>Depressione
                maggiore
              </div>
              <div>
                <i className="fa-solid fa-capsules"></i>Xanax, Clozapina
              </div>
              <div>
                <i className="fa-solid fa-person"></i>Normopeso
              </div> */}
            </div>
          </div>
        </UserDetailsCard>
        <div className="mt-3 mb-3">
          <PatientTabs>
            {tabs.map((tab) => {
              if (tab.disabled) {
                return (
                  <li
                    key={tab.id}
                    style={{ opacity: 0.5 }}
                    onClick={showLockFunctionalityToast}
                  >
                    <i
                      className="fa-solid fa-lock me-1 mb-0"
                      style={{ fontSize: "12px" }}
                    ></i>
                    {tab.label}
                  </li>
                );
              }
              return (
                <li
                  className={tab.id === activeTab ? "active" : ""}
                  onClick={() => {
                    navigate(`/patients/${patientContext?._id}/${tab.id}`, {
                      state: {
                        patient: patientContext,
                      },
                    });
                    // window.history.pushState(
                    //   {},
                    //   "",
                    //   `/patients/${patientContext?._id}/${tab.id}`
                    // );
                    setActiveTab(tab.id);
                  }}
                >
                  {tab.label}
                </li>
              );
            })}
          </PatientTabs>
        </div>

        {activeTab === "registry" ? (
          <>
            <div className="row g-3">
              <div className="col-12 col-sm-6">
                <Box>
                  <div className="d-flex justify-content-between align-items-start">
                    <h4>Dati paziente</h4>
                    <Button
                      onClick={() => {
                        navigate(`/patients/${patientContext?._id}/details`);
                      }}
                      variant="light"
                      size="small"
                    >
                      Modifica
                    </Button>
                  </div>
                  <TableData>
                    <tr>
                      <td>Nome</td>
                      <td>{patientContext?.firstName}</td>
                    </tr>
                    <tr>
                      <td>Cognome</td>
                      <td>{patientContext?.lastName}</td>
                    </tr>
                    <tr>
                      <td>Sesso</td>
                      <td>{renderSexLabel(patientContext?.sex!)}</td>
                    </tr>
                    <tr>
                      <td>Data di nascita</td>
                      <td>
                        {moment(patientContext?.birthday).format("DD/MM/yyyy")}
                      </td>
                    </tr>
                  </TableData>
                </Box>
              </div>
              <div className="col-12 col-sm-6">
                <Box>
                  <div className="d-flex justify-content-between align-items-start">
                    <h4>Lavoro e formazione</h4>
                    <Button
                      onClick={() => {
                        navigate(
                          `/patients/${patientContext?._id}/work-and-training`
                        );
                      }}
                      variant="light"
                      size="small"
                    >
                      Modifica
                    </Button>
                  </div>
                  <TableData>
                    <tr>
                      <td>Titolo di studio</td>
                      <td>{patientContext?.degree}</td>
                    </tr>
                    <tr>
                      <td>Scuola</td>
                      <td>{patientContext?.school}</td>
                    </tr>
                    <tr>
                      <td>Categoria professione</td>
                      <td>{patientContext?.jobCategory}</td>
                    </tr>
                    <tr>
                      <td>Professione</td>
                      <td>{patientContext?.job}</td>
                    </tr>
                  </TableData>
                </Box>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {activeTab === "modules" ? (
          <Box>
            <h4>Moduli</h4>
            <p className="text">
              Assegna e configura i moduli per il paziente.
            </p>

            <HomeworkCard className="">
              <img src="/logo-behavior.png" alt="" />
              <p>
                Monitora e analizza i comportamenti tramite il modulo B-Tracker
              </p>
              <Button fullWidth onClick={goToBeTracker}>
                Monitora <i className="icofont-long-arrow-right"></i>
              </Button>
            </HomeworkCard>
          </Box>
        ) : (
          <></>
        )}
        {activeTab === "accounts" ? (
          <Box>
            <h4>Account collegati</h4>
            <p className="text">
              Crea e gestisci tutti gli account associati al paziente.
            </p>
            <AccountList>
              {accounts?.map((a) =>
                account?._id !== a._id ? (
                  <li key={a._id}>
                    <b>{a.firstName}</b>
                    <br />
                    <span className="text">{a.email}</span>

                    <SimpleIconButton
                      className="right-icon"
                      onClick={() => {
                        detachAccount(a._id!);
                      }}
                    >
                      <i className="fa-solid fa-circle-xmark" />
                    </SimpleIconButton>
                  </li>
                ) : (
                  <></>
                )
              )}
              <Button
                variant="light"
                fullWidth
                onClick={() => {
                  navigate("/accounts/new");
                }}
              >
                Nuovo account
              </Button>
            </AccountList>
          </Box>
        ) : (
          <></>
        )}
      </MainLayout>
    </>
  );
}
