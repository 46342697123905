import moment from "moment";
import React, { useContext } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { AppContext } from "../contexts/AppContext";
import SimpleIconButton from "./SimpleIconButton";

interface NavigationProps {
  active: boolean;
}

const NavigationStyled = styled.nav<NavigationProps>`
  width: 280px;
  height: 100vh;
  position: fixed;
  z-index: 999991;
  left: 0px;
  top: 0px;
  background-color: #0c0d48;
  color: #fff;
  overflow: auto;
  padding: 16px;
  box-sizing: border-box;

  .logo {
    height: 50px;
    max-width: 100%;
    margin-bottom: 50px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      text-decoration: none;
      color: #fff;
      font-weight: 500;
      border-radius: 5px;
      opacity: 0.7;
      margin-bottom: 2px;

      a {
        padding: 12px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
          margin-right: 10px;
        }
      }

      &:hover {
        background-color: #ffffff0d;
      }

      &.active {
        opacity: 1;
        background-color: #ffffff0d;
      }

      &.disabled {
        opacity: 0.4;
        cursor: pointer;

        a {
          pointer-events: none;
        }
      }
    }
  }

  #close-nav-button {
    display: none;
  }

  @media screen and (max-width: 992px) {
    left: ${({ active }) => (active ? "0" : "-330px")};
    width: 330px;
    transition: 0.3s left;

    #close-nav-button {
      display: flex;
      position: absolute;
      right: 10px;
      top: 18px;
      font-size: 22px;
    }
  }
`;

const MenuSection = styled.span`
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 500;
  font-size: 14px;
  padding: 12px;
  margin-top: 16px;
  display: block;
`;

const NavOverlay = styled.nav<NavigationProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999991;
  display: none;

  @media screen and (max-width: 992px) {
    display: ${({ active }) => (active ? "block" : "none")};
  }
`;

export default function Navigation() {
  const { isNavOpen, toggleNav } = useContext(AppContext);
  const location = useLocation();

  function getActiveClass(routeName: string): string {
    if (routeName === location.pathname) {
      return "active";
    }

    return "";
  }

  function showLockFunctionalityToast() {
    toast.error(
      "Questa funzionalità non è disponibile in questa versione di Watson",
      {
        duration: 3000,
      }
    );
  }

  return (
    <>
      <NavOverlay
        active={isNavOpen}
        onClick={() => {
          toggleNav();
        }}
      />
      <NavigationStyled attr-name="navigation" active={isNavOpen}>
        <img src="/logo-watson.png" alt="logo" className="logo" />
        <SimpleIconButton
          id="close-nav-button"
          onClick={() => {
            toggleNav();
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </SimpleIconButton>

        <ul
          onClick={() => {
            toggleNav();
          }}
        >
          {/* <li className={getActiveClass("/")}>
          <NavLink to="/">
            <i className="icofont-home"></i>Dashboard
          </NavLink>
        </li> */}
          <li className={getActiveClass("/")}>
            <NavLink to="/">
              <i className="icofont-users-alt-5"></i>Pazienti
            </NavLink>
          </li>
          <li className="disabled" onClick={showLockFunctionalityToast}>
            <NavLink to="/">
              <i className="fa-solid fa-lock"></i>Appuntamenti
            </NavLink>
          </li>
        </ul>
        <MenuSection>Moduli</MenuSection>
        <ul
          onClick={() => {
            toggleNav();
          }}
        >
          <li>
            <NavLink to="/b-tracker">
              <i className="fa-solid fa-chart-line"></i>Monitoraggio
              comportamento
            </NavLink>
          </li>
          <li className="disabled" onClick={showLockFunctionalityToast}>
            <NavLink to="/">
              <i className="fa-solid fa-lock"></i>Analisi funzionale
            </NavLink>
          </li>
          <li className="disabled" onClick={showLockFunctionalityToast}>
            <NavLink to="/">
              <i className="fa-solid fa-lock"></i>Diario cognitivo
            </NavLink>
          </li>
          <li className="disabled" onClick={showLockFunctionalityToast}>
            <NavLink to="/">
              <i className="fa-solid fa-lock"></i>Monitoraggio umore
            </NavLink>
          </li>
        </ul>
        <MenuSection>Gestione</MenuSection>
        <ul
          onClick={() => {
            toggleNav();
          }}
        >
          <li className={getActiveClass("/account")}>
            <NavLink to="/account">
              <i className="icofont-ui-settings"></i>Impostazioni
            </NavLink>
          </li>
        </ul>
      </NavigationStyled>
    </>
  );
}
